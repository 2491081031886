<template>
  <div class="space-y-4">
    <asom-card class="print-content">
      <cash-declaration-summary-card
        :referenceNo="formData.referenceNo"
        :dateCreated="formData.dateCreated"
        :sourceOfCashEnum="formData.sourceOfCashEnum"
        :sourceOfCash="formData.sourceOfCash"
        :stationName="formData.stationName"
        :remarks="formData.remarks"
        :teBagNo="formData.teBagNo"
        :count1Cents="formData.count1Cents"
        :count2Cents="formData.count2Cents"
        :count5Cents="formData.count5Cents"
        :count10Cents="formData.count10Cents"
        :count20Cents="formData.count20Cents"
        :count50Cents="formData.count50Cents"
        :count1Dollar="formData.count1Dollar"
        :count1DollarNote="formData.count1DollarNote"
        :count2Dollar="formData.count2Dollar"
        :count5Dollar="formData.count5Dollar"
        :count10Dollar="formData.count10Dollar"
        :count20Dollar="formData.count20Dollar"
        :count25Dollar="formData.count25Dollar"
        :count50Dollar="formData.count50Dollar"
        :count100Dollar="formData.count100Dollar"
        :count500Dollar="formData.count500Dollar"
        :count1000Dollar="formData.count1000Dollar"
        :count10000Dollar="formData.count10000Dollar"
        :transactionData="formData.transactionData"
        :psmName="formData.psmName"
      />

      <div class="mt-10 pb-12 bg-white sm:pb-16" id="signature-container">
        <div class="relative">
          <div class="absolute inset-0 h-1/2 bg-gray-50"></div>
          <div class="relative">
            <div class="mx-auto">
              <dl
                class="rounded-lg bg-gray-100 shadow-lg grid sm:grid-cols-2"
              >
                <div
                  class="flex flex-col border-b border-gray-200 p-6 text-start sm:border-0"
                >
                  <div class="bold-text">Staff (Witness)</div>
                  <div>{{ formData.witnessName }}</div>
                  <div class="mt-5">
                    <div class="bold-text">Signature (Witness)</div>
                    <asom-signature-view v-if="formData.witnessSignatureId" :fileId="formData.witnessSignatureId" />
                    <div v-else>
                      <div>Witness Signature Pending</div>
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-col border-t border-b border-gray-100 p-6 text-start sm:border-0 sm:border-l sm:border-r"
                >
                  <div class="bold-text">Staff (Bagger)</div>
                  <div>{{ formData.baggerName }}</div>
                  <div class="mt-5">
                    <div class="bold-text">Signature (Bagger)</div>
                    <asom-signature-view v-if="formData.baggerSignatureId" :fileId="formData.baggerSignatureId" />
                    <div v-else>
                      <div>Bagger Signature Pending</div>
                    </div>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-2 flex justify-end space-x-4 no-print">
        <asom-button
          text="Back"
          variant="secondary"
          @click="$router.push('/cash-mgnt/cash-declaration-form')"
        />
        <asom-button
          text="Print Cash Declaration"
          icon="printer"
          variant="primary"
          @click="printCDF"
        />
      </div>
    </asom-card>
  </div>
</template>

<script>
import { getCashDeclarationFormDetails } from "../../../services/cashManagement.service";
import CashDeclarationSummaryCard from './_CashDeclarationSummaryCard.vue'
import { formDataMixin } from "./_mixins";

export default {
  name: "CashDeclarationFormView",
  components: {
    CashDeclarationSummaryCard
  },
  mixins: [formDataMixin],
  data() {
    return {
      error: null,
      isLoading: false,
      imageSrc: null,
    };
  },
  mounted() {
    this.getCDFDetails();
  },
  methods: {
    printCDF() {
      this.$scrollTopInstant();
      window.print();
    },
    async getCDFDetails() {
      this.error = null;
      this.isLoading = true;
      const resp = await getCashDeclarationFormDetails({
        cashDeclarationFormId: this.$route.params.cashDeclarationFormId,
      });
      if (resp.success) {
        this.setFormData(resp.payload);
      } else {
        this.error = resp.payload;
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.denominations {
  @apply px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900;
}
.noOfNotes {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-500;
}
.totalNotes {
  @apply justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;
}
.value {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-500;
}
.bold-text {
  @apply text-gray-500 font-bold;
}

@media print {
  #signature-container {
    width: 100%;
    display: block;
    overflow:visible;
    height:50rem;
    break-inside: avoid;
    page-break-inside: avoid;
  }
  @page {
    padding-bottom: 2rem;
    size: A3;
    margin: 0;
  }
}
</style>
